import { CubeIcon, TemplateIcon } from "@heroicons/react/solid";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const tabs = [
  { name: 'Bistro', href: '#bistro', icon: TemplateIcon, current: true },
  { name: 'Getränke', href: '#getraenke', icon: CubeIcon, current: false },
  { name: 'Dessert', href: '#speisen', icon: CubeIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Item() {
  return (
    <>
    {/*
      <div className="sticky top-0 z-50 bg-white pt-4 pb-4 border-b border-gray-200">
        <div className="sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <AnchorLink 
                offset='85'
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                  'px-3 py-2 font-medium text-sm rounded-md'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.current ? 'text-slate-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5 inline'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </AnchorLink>
            ))}
          </nav>
        </div>
      </div>
                  */}

      <div id="bistro" className="mt-4 pb-5 border-b border-gray-200">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Bistro</h3>
    </div>

      <div className="mt-6 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

      </div>

      <div id="getraenke" className="mt-4 pb-5 border-b border-gray-200">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Getränke</h3>
    </div>

      <div className="mt-6 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

        <div className="group">
          <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 aspect-square">
            <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" className="inset-0"></span>
                  Basic Tee
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
            </div>
            <p className="text-sm font-medium text-gray-900">$35</p>
          </div>
        </div>

      </div>
    </>
  );
}

export default Item;
