import React from "react";
import Footer from "../home/Footer";
import Header from "./Header";
import Item from "./Item";

export default function Store() {
    return (

        <React.StrictMode>
            <Header />
            <div className="bg-white">
                <div className="max-w-2xl mx-auto py-1 px-4 sm:px-6 lg:max-w-7xl lg:px-8">

                    <Item />

                </div>
            </div>
            <Footer />
        </React.StrictMode>

    );
}

