import Footer from './Footer';
import React from 'react';
import Contact from './Contact';
import Pricing from './Pricing';
import CallToAction from './CallToAction';
import Features from './Features';
import Hero from './Hero';

export default function Website() {
  return (
    <>
      <Hero />
      <Features />
      <CallToAction />
      <Pricing />
      <Contact />
      <Footer />
    </>
  )
}