import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Website from './pages/home/Website';
import SimpleStore from './pages/store/SimpleStore';
import Store from './pages/store/Store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from './pages/admin/Admin';

ReactDOM.render(
  <BrowserRouter>
      <Routes>
        <Route index element={<Website />} />
        <Route path="gren" element={<SimpleStore />} />
        <Route path="login" element={<Admin />} />
        <Route path="*" element={<Store />} />
      </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
