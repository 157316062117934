import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuIcon, XIcon, TranslateIcon } from '@heroicons/react/outline'
import logo from '../../res/logo.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import ReactCountryFlag from "react-country-flag"


const tabs = [
  { name: 'Bistro', href: '#bistro', current: true },
  { name: 'Getränke', href: '#getraenke', current: false },
  { name: 'Süßes', href: '#', current: false },
  { name: 'Alkohol', href: '#', current: false },
  { name: 'Tee', href: '#', current: false },
  { name: 'Pizza', href: '#', current: false },
  { name: 'Pasta', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <>
      <Disclosure as="header" className="bg-white">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
              <div className="relative h-16 flex justify-between">
                <div className="relative z-10 px-2 flex lg:px-0">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block h-8 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                </div>
                <div className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0">
                  <div className="w-full sm:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Suchen
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1  focus:border-slate-500 sm:text-sm"
                        placeholder="Suche"
                        type="search"
                      />
                    </div>
                  </div>
                </div>
                <div className="relative z-10 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100">
                          <span className="sr-only">Open options</span>
                          <ReactCountryFlag
                            countryCode="AT"
                            svg
                            style={{
                              width: '1.5em',
                              height: '1.5em',
                            }}
                            title="AT"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 z-50 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  <ReactCountryFlag
                                    countryCode="GB"
                                    svg
                                    style={{
                                      width: '1.25rem',
                                      height: '1.25rem',
                                    }}
                                    title="GB"
                                  /> English
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  <ReactCountryFlag
                                    countryCode="ES"
                                    svg
                                    style={{
                                      width: '1.25rem',
                                      height: '1.25rem',
                                    }}
                                    title="ES"
                                  /> Espagnol
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  <ReactCountryFlag
                                    countryCode="FR"
                                    svg
                                    style={{
                                      width: '1.25rem',
                                      height: '1.25rem',
                                    }}
                                    title="FR"
                                  /> French
                                </a>
                              )}
                            </Menu.Item>
                            <form method="POST" action="#">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    type="submit"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block w-full text-left px-4 py-2 text-sm'
                                    )}
                                  >
                                    <TranslateIcon className="h-6 w-6 float-left" aria-hidden="true" /> All languages
                                  </button>
                                )}
                              </Menu.Item>
                            </form>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </Disclosure.Button>
                </div>
                <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                  <button
                    type="button"
                    className="flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  >
                    <span className="sr-only">View notifications</span>
                    <TranslateIcon className="h-5 w-5" aria-hidden="true" />
                  </button>

                </div>
              </div>
            </div>

          </>
        )}
      </Disclosure>

      <div className="bg-white max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 sticky top-0 overflow-x-auto no-bar z-[5]" >
        {/*<div className="sm:hidden shadow">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full  focus:border-indigo-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>*/}
        <div className="sm:block py-2">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <AnchorLink
                key={tab.name}
                offset='70'
                href={tab.href}
                className={classNames(
                  tab.current ? 'bg-teal-600 text-white' : 'text-gray-500 hover:text-gray-700',
                  'px-3 py-2 font-medium text-sm rounded-md'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </AnchorLink>
            ))}
          </nav>
        </div>
      </div>
    </>

  )
}