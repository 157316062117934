// use this: https://tailwindui.com/components/marketing/sections/footers#component-8fd8a490ec2fae888154eb83685bbe23
import logo from '../../res/logo.svg'

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  main: [
    { name: 'Impressum', href: '/impressum' },
    { name: 'Datenschutzerklärung', href: '/datenschutz' },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <a href="https://lacarte.at"><img
          className="h-20 mx-auto mt-8"
          src={logo}
          alt="la carte logo"
        /></a>
        <p className="mt-8 text-center text-base text-gray-400">&copy; {new Date().getFullYear()} <a href="https://lacarte.at">lacarte.at</a><br />Alle Rechte vorbehalten.</p>
      </div>
    </footer>
  )
}
